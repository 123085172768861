import { Message, MessageBox } from "element-ui";

// 私有属性，只在当前文件可用
const showMessage = Symbol('showMessage');
const showMessageBox = Symbol('showMessageBox');

export default class domMessage {
    // Message
    message(options, single = true) {
        this[showMessage](options);
    }
    [showMessage](options) {
        if (single) {
            // 判断当前页是否有 el-message 标签，如果没有则执行消息提示操作
            if (document.getElementsByClassName('el-message').length === 0) {
                Message(options)
            }
        } else {
            Message(options)
        }
    }

    // MessageBox
    messageBox(options, single = true) {
        this[showMessageBox](options, single);
    }
    [showMessageBox](options, single) {
        if (single) {
            // 判断当前页是否有 el-message-box 标签，如果没有则执行消息提示操作
            if (document.getElementsByClassName('el-message-box').length === 0 || document.getElementsByClassName('el-message-box__wrapper')[0].style.display == "none") {
                MessageBox(options)
            }
        } else {
            MessageBox(options)
        }
    }
}