<template>
  <div class="sidebar">
    <div class="headline">
      <div class="headline-title" @click="onClickEvent">
        <span class="headlineOne"></span>
        <span class="headlineTwo"></span>
      </div>
      <div class="treeCatalogue">
        <div class="search" v-show="isShow">
          <div class="catalogue">目录</div>
          <div>
            <el-button
              type="text"
              ref="isExpandRef"
              @click="isExpand($event)"
              class="unfold unfoldImg"
              v-show="ifDisplay"
            >
              <img
                src="../assets/img/icon/unfold.png"
                alt=""
                class="iconfont"
              />
            </el-button>
            <el-button
              type="text"
              ref="isExpandRef"
              @click="isExpand($event)"
              v-show="!ifDisplay"
              class="fold"
            >
              <img src="../assets/img/icon/fold.png" alt="" class="foldImg" />
            </el-button>
            <el-button type="text" @click="hideSearchRef" class="searchIcon">
              <img
                src="../assets/img/icon/search.png"
                alt=""
                class="iconfont"
              />
              <!-- <span class="iconfont icon-sousuo"></span> -->
            </el-button>
          </div>
        </div>

        <div class="SearchButton" v-show="!isShow">
          <el-input
            prefix-icon="el-icon-search"
            v-model="filterText"
            placeholder="搜索"
            class="inputSearch"
            style="width: 288px"
            clearable
            size="small"
          >
          </el-input>
          <el-button
            type="text"
            ref="isExpandRef"
            @click="isExpand($event)"
            class="unfold unfoldImg"
            v-show="ifDisplay"
          >
            <img src="../assets/img/icon/unfold.png" alt="" class="iconfont" />
          </el-button>
          <el-button
            type="text"
            ref="isExpandRef"
            class="fold"
            @click="isExpand($event)"
            v-show="!ifDisplay"
          >
            <img src="../assets/img/icon/fold.png" alt="" class="foldImg" />
          </el-button>
        </div>
      </div>
    </div>
    <div class="catalogueTree" ref="treeBox">
      <!-- :expand-on-click-node="false" -->
      <el-tree
        accordion
        v-if="openOrNot"
        class="filter-tree"
        :data="menuList"
        node-key="id"
        :expand-on-click-node="expand"
        :props="defaultProps"
        :default-expanded-keys="defaultKey"
        :default-expand-all="defaultExpand"
        :filter-node-method="filterNode"
        @node-click="handleTreeNodeClick"
        ref="menuTree"
      >
        <template slot-scope="{ node, data }">
          <router-link :to="{ path: `/article?id=${data.id}` }">{{
            node.label
          }}</router-link>
        </template>
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span class="isAdd">
            <!-- @click="addedClick()"trigger="click" -->
            <el-dropdown
              @command="handleCommand($event, data)"
              @click="addedClick()"
            >
              <el-button
                class="addBtn"
                v-show="node.data.isAdd"
                type="text"
                @click="expandClick()"
                @click.stop="expandStopClick()"
              >
                <img src="../assets/img/rtaLogo/add.svg" alt="" />
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">文本编辑</el-dropdown-item>
                <el-dropdown-item command="b">图文编辑</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </span>
      </el-tree>
      <!--在el-dialog中加入了v-if="editDialogVisible"绑定的是控制对话框显示隐藏的字段，
      editDialogVisible在data中定义为false，这样就使得对话框在关闭时被销毁再点开时又被重新渲染包括里边的数据。 -->
      <el-dialog
        title="增加词条"
        customClass="customWidth"
        :visible.sync="addDialogFormVisible"
        :close-on-click-modal="false"
        :append-to-body="false"
        :before-close="handleClose"
        v-if="addDialogFormVisible"
        @close="closeDialog"
      >
        <el-form :model="addForm" :rules="addRules" ref="newlyIncreased">
          <el-form-item
            label="类别"
            :label-width="formLabelWidth"
            prop="category"
          >
            <el-select v-model="addForm.category" placeholder="请选择词条类别">
              <el-option
                v-for="item in categoryoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="词条名称"
            :label-width="formLabelWidth"
            prop="title"
          >
            <el-input v-model="addForm.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            class="textarea-item"
            :label-width="formLabelWidth"
            prop="content"
            v-if="textShow"
          >
            <el-input
              type="textarea"
              placeholder="说点什么吧"
              v-model="addForm.content"
              maxlength="500"
              show-word-limit
              class="textarea"
              style=" 100%"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label=""
            :label-width="formLabelWidth"
            prop="richContent"
            v-if="!textShow"
          >
            <yk-editor
              ref="editorRef"
              @sendContnet="getContent"
              :content="addForm.richContent"
            ></yk-editor>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="open(addForm)">取 消</el-button>
          <el-button type="primary" @click="getNewlyIncreased(addForm)"
            >确 定</el-button
          >
        </div>
      </el-dialog>
      <!-- 没有权限词条添加说明对话框 -->
      <el-dialog
        customClass="explainDialog"
        v-if="explainDialogVisible"
        title="您当前权限不可添加词条！"
        :visible.sync="explainDialogVisible"
        :before-close="handleClose"
        :width.sync="explainWidth"
      >
        <div class="explainTitle" v-if="createLimit">
          <span class="title">词条添加说明</span>

          <span class="ti"
            >为了防止恶意攻击或不良信息传播情况的发生，用户词条添加采用“用户提交-管理员审核-审核通过展示”的模式，且每人每日提交次数不宜超过10次。这样既可以提高百科词条的质量，又能降低恶意攻击、不良信息传播的风险。</span
          ><span class="tix"
            >一旦您触发提交限制，则是由于提交次数过多或存在恶意行为，请您规范自身行为。如不属于上述情况，请联系客服反馈！</span
          >
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="explainDialogVisible = false"
            >我知道了</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ykEditor from "@/components/ykeditor.vue";
import {
  getEntryData,
  getTitleEntryData,
  geiAddCategory,
  getCateAddData,
} from "@/api/Home.js";
export default {
  components: { ykEditor },
  // props: {
  //   addForm: {
  //     type: Array,
  //     default: function () {
  //       return [];
  //     },
  //   },
  // },
  data() {
    return {
      filterText: "",
      menuList: [],
      defaultProps: {
        children: "children",
        label: "name",
        disabled: function (data, node) {
          // console.log("data", node);
        },
      },
      defaultExpand: false,
      openOrNot: true,
      ifDisplay: false,
      array: [],
      isShow: true,
      addDialogFormVisible: false,
      addForm: {
        category: "",
        title: "",
        content: "",
        richContent: "",
      },
      categoryoptions: [],
      addRules: {
        category: [
          { required: true, message: "请选择词条类别", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入词条名称", trigger: "blur" }],
        content: [
          { required: true, message: "请输入词条内容", trigger: "blur" },
        ],
        richContent: [
          { required: true, message: "请输入词条内容", trigger: "blur" },
        ],
      },
      formLabelWidth: "130px",
      isCreate: false,
      command: "",
      textShow: false,
      richShow: false,
      value: "",
      label: "",
      explainDialogVisible: false,
      createLimit: false,
      explainWidth: "31.25%",
      expand: true,
      defaultKey: [],
    };
  },
  created() {
    this.getCatalogue();
  },
  watch: {
    filterText(val) {
      this.$refs.menuTree.filter(val);
    },
  },
  mounted() {
    this.load_data();
  },
  inject: ["reload"],
  methods: {
    //页面刚加载的时候，开始判断，如果有用户名，就显示在页面上，如果没有用户名的话，就显示登录名称
    async load_data() {
      var theme = localStorage.getItem("token");
      if (theme == null || theme == "") {
        // 未登录不显示添加词条按钮
        this.isCreate = false;
      } else {
        // 显示用户信息
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.isCreate = userInfo.isCreate;
        this.createLimit = userInfo.createLimit;
      }
    },
    //点击添加节点不展开
    expandClick() {
      this.expand = false;
    },
    //click.stop阻止事件冒泡 节点不展开
    expandStopClick() {
      this.expand = true;

      // this.$refs.menuTree.store.nodesMap[data.id].expanded = true;
    },
    //新增词条点击事件
    addedClick() {
      const token = localStorage.getItem("token");
      if (token == null || token == "") {
        // 监听接收登录消息
        this.$EventBus.$emit("changedialogFormVisible");
      } else {
        this.addForm = {};
        this.categoryoptions = [];
        if (this.isCreate && this.createLimit == false) {
          this.addDialogFormVisible = true;
          this.getCategoryName();
          // this.addForm.category = this.value;
          this.$set(this.addForm, "category", this.value);
        } else {
          this.addDialogFormVisible = false;
          this.explainDialogVisible = true;
        }
        if (this.createLimit == false) {
          this.explainWidth = "15%";
        } else {
          this.explainWidth = "31.25%";
        }
      }
    },
    //点击增加此提案文本选择菜单项后会触发事件，通过相应的菜单项 key 进行不同的操作
    handleCommand(command, data) {
      this.value = data.id;
      this.label = data.name;
      // console.log(this.value);

      if (command === "a") {
        this.textShow = true;
      } else if (command === "b") {
        this.textShow = false;
      }
      this.addedClick();
    },

    // 获取类别
    async getCategoryName() {
      try {
        const { data: res } = await getCateAddData();
        res.ret_data.categories.forEach((item) => {
          const categoryObj = {
            value: item.id,
            label: item.name,
          };
          this.categoryoptions.push(categoryObj);
        });
        //console.log(this.categoryoptions);
      } catch (err) {
        // console.log(err);
      }
    },
    //请求新增词条接口
    getNewlyIncreased() {
      if (this.textShow == false) {
        this.addForm.richContent = this.$refs.editorRef.getContent();
      }

      this.$refs.newlyIncreased.validate(async (valid) => {
        if (valid) {
          const { category, title, content, richContent } = this.addForm;
          let params = {
            id: this.editId,
            category,
            title,
            content,
            richContent,
          };
          const { data: res } = await geiAddCategory(params);
          if (res.ret_code == 0) {
            this.$message({
              message: "增加词条成功！",
            });
            this.addDialogFormVisible = false;
            this.addForm = {};
          } else {
            this.$confirm(res.ret_msg, {
              confirmButtonText: "确定",
              type: "",
              customClass: "question-confirm",
            });
            //   this.$message({
            //     type: "error",
            //     message: res.ret_msg,
            //   });
          }
        } else {
          this.$message({
            type: "error",
            message: "增加词条失败！",
          });
          return false;
        }
      });
    },
    //获取富文本编译器内容
    getContent(val) {
      this.addForm.content = val;
      // console.log(val);
    },
    //对话框取消提示
    open(done) {
      this.$confirm("信息未保存，确认关闭？", "提示")
        .then((_) => {
          this.addDialogFormVisible = false;
          this.addForm = {};
        })
        .catch((_) => {
          this.addDialogFormVisible = true;
        });
    },
    //关闭前的回调，会暂停 Dialog 的关闭这个是对话框右上角叉
    handleClose(done) {
      this.$confirm("信息未保存，确认关闭？", "提示")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //关闭弹框的事件
    closeDialog() {
      this.addForm = {}; //清空数据
    },

    onClickEvent() {
      this.isShow = true;
      this.filterText = "";
    },
    getLeafKeys(node, arr) {
      //console.log("node",node);
      if (!node.children) {
        return arr.push(node.id);
      } else {
        node.children.forEach((item) => {
          this.getLeafKeys(item, arr);
        });
      }
    },
    // 递归获取名称
    acquirename(node) {
      // this.myarray.push(node.data.name)
      this.myarray = [node.data.name, ...this.myarray];
      if (node.parent !== null) {
        if (Array.isArray(node.parent.data)) return;
        this.acquirename(node.parent);
      }
    },
    setKey(e) {
      this.$nextTick(() => {
        this.defaultKey = [e];
        // const div = this.$refs.treeBox;
        // div.scrollTop = div.scrollHeight;
      });
    },
    // 点击树节点事件处理
    handleTreeNodeClick(data, node, elem) {
      if (data.id == 0) {
        this.defaultExpand = true;
        this.isExpand();
      }
      //树节点只有一级没有子节点时禁用点击
      if (data.children != null) {
        if (data.children.length == 0) {
          //console.log("123",elem.$el.lastElementChild.style)
          elem.$el.lastElementChild.style.cursor = "not-allowed";
          elem.$el.lastElementChild.style.color = "red";
          return;
        }
      }
      if (node !== null && node.isLeaf) {
        this.myarray = [];
        this.acquirename(node);
      }

      this.$store.commit("changebreadlist", this.myarray);

      this.onClickEvent();
      // let res = this.$refs.menuTree.getCheckedNodes()
      if (data.length != null && data.children.length > 0) {
        data.id = data.children[0].id;
      } else {
        data.id = data.id;
      }

      //  只走最后一级逻辑
      // 判断是不是最后一个子节点 如果不是则不继续走 是继续执行
      if (!node.isLeaf) return;
      this.$router.push({ path: `/article?id=${data.id}` });
    },
    //对树节点进行筛选时执行的方法，返回 true 表示这个节点可以显示，返回 false 则表示这个节点会被隐藏
    filterNode(value, data, node) {
      if (!value) {
        return true;
      }
      if (data.name.indexOf(value) !== -1) {
        //增加搜索框页 面
        this.$router.replace({ path: "/test", query: { content: value } });
        return data.name.indexOf(value) !== -1;
      }
    },
    // 全部展开/折叠
    isExpand(event) {
      this.defaultExpand = !this.defaultExpand;
      this.ifDisplay = !this.ifDisplay;
      this.openOrNot = false;
      setTimeout(() => {
        this.openOrNot = true;
      }, 1);
    },
    //用ref控制元素隐藏显示
    hideSearchRef() {
      this.isShow = false;
    },
    // 获取词条目录zuo
    async getCatalogue() {
      const { data: res } = await getEntryData();
      let synopsis = {
        name: "首页",
        id: 0,
        disabled: true,
      };

      this.menuList = res.ret_data;

      //unshift() 方法向数组的开头添加id为o的简介页面。
      this.menuList.unshift(synopsis);
    },
  },
};
</script>

<style lang="scss">
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}
::v-deep .el-tree-node__content:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.sidebar {
  height: 100% !important;
  width: 100% !important;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  .aaaa {
    cursor: not-allowed;
  }
  .headline {
    .headline-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      //margin: 18px 0 0 20px;
      height: 60px;
      .headlineOne {
        font-family: Roboto-Regular;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
      .headlineTwo {
        font-size: 12px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.8);
        line-height: 18px;
      }
    }

    .treeCatalogue {
      .search {
        height: 32px;
        line-height: 32px;
        margin: 0 20px 0 20px;
        display: flex;
        justify-content: space-between;
        .catalogue {
          width: 28px;
          height: 14px;
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
          // margin-left: 8px;
          color: rgba(255, 255, 255, 0.8);
        }
        .fold {
          .el-icon-s-unfold {
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.6);
          }
        }
        .open {
          .el-icon-s-fold {
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.6);
            margin-left: 5px;
          }
        }
        .el-button {
          padding: 0;
          margin-left: 5px;
          // .el-icon-s-fold {

          //   color: rgba(255, 255, 255, 0.6);
          //   font-size: 16px;
          // }
          // .el-icon-s-unfold {

          //   color: rgba(255, 255, 255, 0.6);
          //   font-size: 16px;
          // }
          .el-icon-search {
            // color: rgba(255, 255, 255, 0.6);
            font-size: 16px;
          }
          .iconfont {
            // color: rgba(255, 255, 255, 0.6);
          }
        }
        .searchIcon {
          margin-right: 8px;
        }
      }
      .SearchButton {
        display: flex;
        margin: 20px 20px 0 20px;
        .unfold {
          margin-left: 5px;
        }
        .fold {
          margin-left: 5px;
          .el-icon-s-fold {
            margin-left: 5px;
            width: 16px;
            height: 16px;
          }
          .el-icon-s-unfold {
            display: flex;
            align-items: center;
            // color: rgba(255, 255, 255, 0.6);
          }
          .foldImg {
          }
        }
        .el-button {
          padding: 0;
          .el-icon-s-unfold {
            // color: rgba(255, 255, 255, 0.6);
            font-size: 20px;
            margin-left: 5px;
          }
          .el-icon-s-fold {
            width: 20px;
            height: 20px;
            // color: rgba(255, 255, 255, 0.6);
            font-size: 16px;
            // margin-left: 5px;
          }
          .el-icon-s-unfold {
            width: 20px;
            height: 20px;
            // color: rgba(255, 255, 255, 0.6);
            font-size: 16px;
          }
        }
      }
      .inputSearch {
        width: 288px;
        display: flex;
        input::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
        .el-input__inner {
          width: 288px;
          background: rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          border: 1px solid #ffffff;
          color: rgba(255, 255, 255, 0.8);
        }

        .el-input__prefix {
          .el-input__icon {
            line-height: 32px;
          }
        }
      }
    }
  }
  .catalogueTree {
    //background: #33353E !important;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;

    .el-tree {
      background: #32353f !important;
      color: #ffffff;
      margin: 20px;
      height: calc(100%-22) !important;
      .el-tree-node {
        .is-current > .el-tree-node__content {
          background-color: rgba(255, 255, 255, 0.1) !important;
          .custom-tree-node {
            span {
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: #efc394;
              line-height: 28px;
            }
          }
        }
        .el-tree-node__content {
          // margin-top: 4.5px !important;
          height: 32px;
          background-color: transparent !important;
          .el-tree-node:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
          .el-tree-node__label {
            margin: 4.5px !important;
            color: rgba(255, 255, 255, 0.8) !important;
          }
          .el-tree-node__expand-icon {
            padding: 6px 6px 6px 0 !important;
          }
          .custom-tree-node {
            width: 100%;
            height: 25px;
            line-height: 25px;
            display: flex;
            justify-content: space-between;
            span {
              font-size: 14px;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.8);
            }
            .isAdd {
              height: 25px;
              line-height: 25px;
              display: flex;
              align-items: center;
              margin-right: 10px;
              .addBtn {
                height: 16px;
                padding: 0;
                display: none;
              }
            }
          }
        }
        .el-tree-node__content:hover {
          background-color: rgba(255, 255, 255, 0.1) !important;
          .isAdd {
            height: 25px;
            line-height: 25px;
            .addBtn {
              display: block;
            }
          }
        }
      }

      .el-tree-node:focus {
        background: #32353f !important;
        .el-tree-node__content {
          // background-color: rgba(255, 255, 255, 0.1) !important;
          //color: rgba(239, 195, 148, 1) !important;
          .is-leaf + .el-tree-node__label {
            color: rgba(239, 195, 148, 1) !important;
          }
        }
        .el-tree-node__children {
          .is-leaf + .el-tree-node__label {
            color: #ffffff !important;
          }
        }
        .el-tree-node__children:hover {
          background: #32353f !important;
        }
      }
      .is-expanded {
        background: #32353f !important;
      }
    }
    .customWidth {
      width: 50%;
      .el-form-item__label {
        text-align: left !important;
      }
      .el-input {
        width: 200px !important;
      }
      .el-dialog__headerbtn:focus .el-dialog__close,
      .el-dialog__headerbtn:hover .el-dialog__close {
        color: #33353e !important;
      }
      .el-dialog__body {
        .el-form {
          .textarea-item {
            margin: 24px 0 0 0 !important;
            .el-form-item__content {
              height: 330px !important;
              .textarea {
                .el-textarea__inner {
                  min-height: 330px !important;
                }
              }
            }
          }
        }
      }
      .el-dialog__footer {
        .el-button {
          padding: 8px 16px !important;
        }
        .el-button--primary {
          background-color: #33353e;
          border-color: #33353e;
        }
        .el-button--default:active {
          color: #33353e;
          border-color: #efc394;
          background: #fff;
        }
        .el-button--default:hover {
          background: #fff;
          color: #33353e;
          border-color: #efc394;
          outline: 0;
        }
      }
    }
    .explainDialog {
      margin-top: 30vh !important;
      background-color: #33353e;
      // width: 600px !important;
      .el-form-item__label {
        text-align: left !important;
      }
      .el-input {
        width: 200px !important;
      }
      .el-dialog__header {
        display: flex;
        height: 24px;
        line-height: 24px;
        padding: 24px 24px 24px 24px !important;
        .el-dialog__title {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
        }
        .el-dialog__headerbtn {
          display: none;
        }
      }
      .el-dialog__body {
        padding: 0 !important;
        border-radius: 4px;
        .explainTitle {
          display: flex;
          flex-direction: column;
          text-align: justify;
          .title {
            // width:100%;
            margin: 12px 24px 14px;
            height: 14px;
            line-height: 14px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 2px;
            padding: 13px 12px;
            font-size: 14px;
            font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            font-weight: 600;
            color: rgba(255, 255, 255, 0.8);
          }
          .ti {
            margin: 0 24px 14px;
          }
          .tix {
            margin: 0 24px 30px;
          }
          span {
            margin-bottom: 14px;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      .el-dialog__footer {
        .el-button {
          padding: 8px 16px !important;
        }
        .el-button--default:active {
          color: #33353e;
          border-color: #efc394;
          background: #fff;
        }
        .el-button--default:hover {
          background: #fff;
          color: #33353e;
          border-color: #efc394;
          outline: 0;
        }
        .el-button--primary {
          background-color: #efc394;
          border: 1px solid rgba(255, 255, 255, 0.2);
          span {
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            color: #33353e;
          }
        }
      }
    }
  }
  .catalogueTree::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .catalogueTree::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.1);
  }
  .catalogueTree::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
<style lang="scss">
.el-dropdown-menu {
  background-color: #31343e !important;
  //border-color: #31343e !important;
  padding: 0 !important;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.2),
    0px 6px 16px 0px rgba(0, 0, 0, 0.32), 0px 3px 6px -4px rgba(0, 0, 0, 0.48) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  .el-dropdown-menu__item {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
  }
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: rgba(255, 255, 255, 0.8);
  }
}
.el-dropdown-menu[x-placement^="bottom"] .popper__arrow {
  /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}
.el-dropdown-menu[x-placement^="bottom"] .popper__arrow::after {
  /*el-popover上边三角表层的颜色---也就是视觉上的角*/
  border-bottom-color: #31343e !important;
}
.el-message-box__wrapper {
  .el-message-box {
    background-color: #31343e !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    width: 400px !important;
    height: 155px !important;
    .el-message-box__header {
      padding: 16px 26px 25px 22px !important;
      height: 26px;
      .el-message-box__title {
        width: 320px !important;
        span {
          width: 64px;
          height: 24px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 0.85);
          line-height: 24px;
        }
      }
      .el-message-box__headerbtn {
        top: 26px !important;
        right: 26px !important;
        .el-message-box__close {
        }
      }
    }
    .el-message-box__content {
      padding: 0 15px 25px 22px !important;
      .el-message-box__container {
        .el-message-box__message {
          p {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(255, 255, 255, 0.65);
            line-height: 22px;
            a {
              text-decoration: none;
              font-size: 14px;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
              font-weight: 500;
              color: #efc394;
            }
          }
        }
      }
    }
    .el-message-box__btns {
      height: 24px !important;
      padding: 0 26px 16px 15px !important;
      .el-button {
        width: 49px;
        height: 24px;
        border-radius: 2px;
        background: #33353e;
        border: 1px solid rgba(255, 255, 255, 0.2);
        span {
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 0.65);
        }
      }
      .el-button--primary {
        background: #edbd88;
        span {
          color: #33353e;
        }
      }
      .el-button--small {
        padding: 0;
      }
    }
  }
}
.el-message-box__wrapper {
  .question-confirm {
    width: 200px;
    height: 155px !important;
    background: rgba(51, 53, 62, 1);
    border: none;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.2),
      0px 6px 16px 0px rgba(22, 23, 28, 0.32),
      0px 3px 6px -4px rgba(51, 53, 62, 0.48);
    border-radius: 4px;
    padding-bottom: 0 !important;

    .el-message-box__header {
      .el-message-box__headerbtn {
        top: 17px !important;
        .el-icon-close {
          color: rgba(255, 255, 255, 0.8);
        }
      }
      padding: 10px 15px 10px !important;
    }
    .el-message-box__content {
      padding: 10px 15px 0 15px !important;
      .el-message-box__container {
        .el-message-box__message {
          p {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(255, 255, 255, 0.8);
            line-height: 22px;
          }
        }
      }
    }
    .el-message-box__btns {
      height: 30px !important;
      padding: 10px 16px 15px 15px !important;
      display: flex;
      justify-content: flex-end;
      .el-button {
      }
      .el-button--primary {
        margin: 0;
        padding: 0;
        display: flex !important;
        width: 49px;
        line-height: 30px;
        height: 30px;
        border-radius: 2px;
        background: #efc394;
        border: 1px solid rgba(255, 255, 255, 0.2);
        span {
          width: 49px;
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: #33353e;
        }
      }
      .el-button--small {
        display: none;
      }
    }
  }
}
</style>
