<template>
  <div class="app">
    <el-container class="wiki" style="height: 100%">
      <el-header class="wikiHeader"><Top ref="wikiTop" /></el-header>
      <el-container
        class="wikiContent"
        direction="horizontal"
        style="height: calc(100% - 100px)"
      >
        <el-aside width="360px" class="app-side app-side-left" v-show="isShow">
          <Sidebar ref="wikiSidebar" />
        </el-aside>
        <el-container direction="vertical">
          <el-header class="app-header" v-show="isShow">
            <div class="breadcrumb">
              <ContentHeader :purchase="true" />
            </div>
          </el-header>

          <el-main class="app-body">
            <template>
              <router-view @changeTree="changeTree" />
            </template>
          </el-main>
          <!-- <el-footer><Footer /></el-footer> -->
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// 头部组件 0
import Top from "../layout/top.vue";
// 侧边组件
import Sidebar from "../layout/sidebar";
// 内容组件
import ContentHeader from "../layout/contentHeader";
//
//import Catalogue from "../views/catalogue.vue";
export default {
  name: "App",
  components: { Top, Sidebar, ContentHeader },
  data() {
    return {
      isShow: true,
    };
  },
  computed: {},
  props: {
    menus: {
      type: Array,
      default: function () {
        return [];
      },
      required: false,
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.path.indexOf("Catalogue") > -1) {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
      },
    },
  },
  methods: {
    // toggleSideBar() {
    //   this.isCollapse = !this.isCollapse;
    // },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    changeTree(e) {
      if (e && e !== 0) {
        // setTimeout(() => {
        //   // let node = this.$refs.wikiSidebar.$refs.menuTree.getNode(e);

        // }, 3000);
        // console.log("e", e);
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.wikiSidebar.setKey(e);
          }, 1500);
        });
      }
    },
  },
  mounted() {},
  created() {},
};
</script>

<style lang="scss">
body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #33353e;
}
#app {
  height: 100%;
  .app {
    height: 100%;
    .wiki {
      height: 100%;
      .wikiHeader {
        padding: 0;
        height: 100px !important;
      }
      //  .app-header{
      //    height: 37px !important;
      //  }
      // .el-header,

      .wikiContent {
        border-top: 1px solid;
        border-image: linear-gradient(270deg, #33353e 0%, #f6daa8 100%) 1;
        width: 100%;
        height: 100%;
        .app-side {
          border-right: solid 1px rgba(204, 204, 204, 0.1);
        }
        .el-container.is-vertical {
          .app-header {
            padding: 0 0 !important;
            height: 60px !important;
            border-bottom: 1px solid rgba(204, 204, 204, 0.1);
            .el-menu {
              background: #32353f !important;
            }
            .el-menu.el-menu--horizontal {
              border-bottom: solid 1px #32353f;
              .el-menu-item {
                color: #ffffff !important;
              }
            }
            .breadcrumb {
              padding: 16px 20px 0 20px;
              background-color: #32353f;
              .el-breadcrumb__item {
                .el-breadcrumb__inner {
                  width: 28px;
                  height: 14px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #ffffff;
                  line-height: 21px;
                }
              }
            }
          }
          .el-main.app-body {
            padding: 0;
          }
          .app-body::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          .app-body::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.1);
          }
          .app-body::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
          }
          .el-footer {
            color: #ffffff;
            text-align: center;
            margin: 0 auto;
            line-height: 38px;
          }
        }
      }
    }
  }
}
</style>
