<template>
  <div class="box" style="overflow-y: auto; height: 100vh">
    <div class="Publish_header">
      <Top />
    </div>
    <div class="Publish_content">
      <div class="Publish_tittle">
        <div class="homePage_logo">
          <a href="https://lib.rta.academy/#/article?id=0"
            ><img src="@/assets/img/icon/homePage.png" alt=""
          /></a>
        </div>
        <div class="get_back">></div>
        <span>我的发布</span>
      </div>
      <div class="publishNav">
        <el-input
          v-model="search"
          prefix-icon="el-icon-search"
          placeholder="搜索"
          class="inputSearch"
          style="width: 288px"
          clearable
          size="small"
        >
        </el-input>
      </div>
      <div class="Publish_list">
        <!-- v-cloak -->
        <el-table
          v-if="PublishData.length > 0"
          :data="
            PublishData.filter(
              (data) =>
                !search ||
                data.title.toLowerCase().includes(search.toLowerCase())
            )
          "
          style="width: 100%"
          :header-cell-style="{
            background: '#33353E',
            color: 'rgba(255, 255, 255, 0.6)',
            borderColor: 'rgba(255, 255, 255, 0.1)',
          }"
          :cell-style="{
            background: '#33353E',
            color: 'rgba(255, 255, 255, 0.6)',
            borderColor: 'rgba(255, 255, 255, 0.1)',
          }"
          :default-sort="{ prop: 'date', order: 'descending' }"
        >
          <el-table-column prop="title" label="名称"> </el-table-column>
          <el-table-column prop="create_time" label="创建时间" sortable>
          </el-table-column>
          <el-table-column label="审核状态">
            <template slot-scope="scope">
              <div
                class="is_passed"
                :style="
                  scope.row.is_passed == true
                    ? 'background: #52c41a'
                    : scope.row.is_passed !== null
                    ? 'background: red'
                    : 'background:#FAAD14'
                "
              ></div>
              <!-- {{
              scope.row.is_passed == true
                ? "通过"
                : "未通过" + "（" + scope.row.reason + "）"
            }} -->
              {{
                scope.row.is_passed == true
                  ? "通过"
                  : scope.row.is_passed != null
                  ? "未通过" + "（" + scope.row.reason + "）"
                  : "待审核"
              }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="reason" label="审核原因"> </el-table-column> -->
          <el-table-column prop="integra" label="获得积分"> </el-table-column>
          <el-table-column fixed="right" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                class="operating-button"
                style="color: rgba(255, 255, 255, 0.8)"
                @click="editUI(scope.row)"
                v-if="scope.row.is_passed == false"
                >编辑</el-button
              >

              <el-button
                @click.native.prevent="deleteRow(scope.$index, PublishData)"
                style="color: rgba(255, 255, 255, 0.8)"
                class="operating-button"
                type="text"
                size="small"
              >
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagingBox">
          <Paging
            :pageNum="pageParams.pageNum"
            :total="total"
            :pageSize="pageParams.pageSize"
            @sizeChange="sizeChange"
            @currentChange="currentChange"
          />
        </div>
        <!-- 编辑 -->
        <el-dialog
          title="编辑新增的词条"
          customClass="customWidth"
          :visible.sync="newlyIncreasedClick"
          :close-on-click-modal="false"
          :append-to-body="false"
          :before-close="handleClose"
          @close="closeDialog"
        >
          <el-form
            :model="newlyIncreasedForm"
            :rules="rules2"
            ref="newlyIncreased"
          >
            <el-form-item label="类别" :label-width="formWidth" prop="category">
              <el-select
                v-model="newlyIncreasedForm.category"
                placeholder="请选择词条类别"
              >
                <el-option
                  v-for="item in categoryoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="词条名称"
              :label-width="formWidth"
              prop="title"
            >
              <el-input
                v-model="newlyIncreasedForm.title"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item
              label="VIP等级开放等级"
              :label-width="formWidth"
              prop="vip"
            >
              <el-select v-model="newlyIncreasedForm.vip" placeholder="请选择">
                <el-option
                  v-for="item in vipOptions"
                  :key="item.value2"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item
              class="textarea-item"
              :label-width="formWidth"
              prop="content"
              v-if="textShow"
            >
              <el-input
                type="textarea"
                placeholder="说点什么吧"
                v-model="newlyIncreasedForm.content"
                maxlength="500"
                show-word-limit
                class="textarea"
                style=" 100%"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label=""
              :label-width="formWidth"
              prop="richContent"
              v-if="!textShow"
            >
              <yk-editor
                ref="editorRef"
                id="editor"
                @sendContnet="getContent"
                :content1="newlyIncreasedForm.richContent"
              ></yk-editor>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="open">取 消</el-button>
            <el-button type="primary" @click="getNewlyIncreased"
              >新增</el-button
            >
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import ykEditor from "@/components/ykeditor.vue";
import Top from "@/layout/top.vue";
import {
  geiAddCategory,
  getVipData,
  getCateAddData,
  getAddListData,
} from "@/api/Home.js";
import { getListDelete, getListEdit } from "@/api/add.js";
import Paging from "@/components/paging/index.vue"; //引入封装的分页器文件
export default {
  components: { Top, Paging, ykEditor },
  data() {
    return {
      PublishData: [],
      search: "",
      searchValue: "",
      newlyIncreasedClick: false,
      newlyIncreasedForm: {
        name: "",
        category: "",
        vip: "",
        content: "",
      },
      rules2: {
        title: [
          { required: true, message: "请输入词条名称", trigger: "change" },
        ],
        category: [
          { required: true, message: "请选择词条类别", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入词条内容", trigger: "blur" },
        ],
        richContent: [
          { required: true, message: "请输入词条内容", trigger: "blur" },
        ],
      },
      categoryoptions: [],
      value: "",
      vipOptions: [],
      formWidth: "130px",
      vipName: "",
      infoText: "",
      content: "",
      pageParams: {
        pageNum: 1, //默认第一页
        pageSize: 10, //一页展示10条
      },
      total: 0, //数据总条数
      timeArr: "",
      id: "",
      textShow: false,
      editId: "",
    };
  },
  computed: {},
  created() {
    this.getReleaseDirectory();
  },
  mounted() {},
  methods: {
    //回显可编辑的新增数据
    async editUI(item) {
      this.editId = item.id;
      this.newlyIncreasedClick = true;
      this.getCategoryName();
      // this.getVipLevel();

      this.newlyIncreasedForm = item;
      this.textShow = item.content ? true : false;
    },
    //获取富文本编译器内容
    getContent(val) {
      this.newlyIncreasedForm.content = val;
    },
    newlyIncreasedClic() {
      this.newlyIncreasedClick = true;
      //请求vip信息
      this.vipOptions = [];
      this.categoryoptions = [];
      this.getVipLevel();
      this.getCategoryName();
    },
    //关闭前的回调，会暂停 Dialog 的关闭
    handleClose(done) {
      this.$confirm("信息未保存，确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // // 获取vip信息
    // async getVipLevel() {
    //   const { data: res } = await getVipData();
    //   res.ret_data.vips.forEach((item) => {
    //     const vipObj = {
    //       value: item.id,
    //       label: item.vip_name,
    //     };
    //     this.vipOptions.push(vipObj);
    //   });
    // },
    // 获取类别
    async getCategoryName() {
      const { data: res } = await getCateAddData();
      res.ret_data.categories.forEach((item) => {
        const categoryObj = {
          value: item.id,
          label: item.name,
        };
        this.categoryoptions.push(categoryObj);
      });
    },
    //日期时间转换
    rTime(date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    //获取个人新增记录
    async getReleaseDirectory() {
      let params = {
        page_size: this.pageParams.pageSize,
        page_index: this.pageParams.pageNum,
      };
      const { data: res } = await getAddListData(params);
      this.total = res.total;

      let PublishArr = res.ret_data;
      PublishArr.forEach((item) => {
        this.timeArr = this.rTime(item.create_time);
        const PublishObj = {
          category: item.category,
          title: item.title,
          create_time: this.timeArr,
          integra: item.integra,
          reason: item.reason,
          is_passed: item.is_passed,
          id: item.id,
          content: item.content,
          richContent: item.richContent,
        };
        this.PublishData.push(PublishObj);
      });
    },
    //每页展示几条按钮操作
    sizeChange(val) {
      this.pageParams.pageSize = val;
      this.PublishData = [];
      this.getReleaseDirectory();
    },
    // 点击页码及上一页下一页按钮操作
    currentChange(val) {
      this.pageParams.pageNum = val;
      this.PublishData = [];
      this.getReleaseDirectory();
    },
    //删除新增记录词条
    deleteRow(index, rows) {
      this.id = rows[index].id;
      this.$confirm("", "确定要删除记录？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
        customClass: "Teletter-confirm",
      })
        // rows.splice(index, 1);
        .then(async () => {
          let params = {
            id: this.id,
          };
          const { data: res } = await getListDelete(params);
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getReleaseDirectory();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },
    //编辑新增词条接口
    getNewlyIncreased() {
      if (!this.textShow) {
        this.newlyIncreasedForm.richContent = this.$refs.editorRef.getContent();
      }
      this.$refs.newlyIncreased.validate(async (valid) => {
        if (valid) {
          const { data: res } = await getListEdit(params);
          //console.log("添加的内容", this.newlyIncreasedForm);
          if (res.ret_code == 0) {
            this.$message({
              message: "增加词条成功！",
            });
            this.newlyIncreasedClick = false;
            this.newlyIncreasedForm = {};
          }
        } else {
          this.$message({
            type: "error",
            message: "增加词条失败！",
          });
          return false;
        }
      });
    },
    //关闭弹框的事件
    closeDialog() {
      this.newlyIncreasedForm = {}; //清空数据
    },
    //对话框取消提示
    open(done) {
      this.$confirm("信息未保存，确认关闭？", "提示")
        .then((_) => {
          this.newlyIncreasedClick = false;
          this.newlyIncreasedForm = {};
        })
        .catch((_) => {
          this.newlyIncreasedClick = true;
        });
    },
  },
};
</script>
<style lang="scss">
.Publish_header {
  border-bottom: 1px solid;
  border-image: linear-gradient(270deg, #33353e 0%, #f6daa8 100%) 1;
}
.Publish_content {
  margin: 0 40px 22px 40px;
  .publishNav {
    display: flex;
    justify-content: space-between;
    .inputSearch {
      margin: 16px 0 16px 0;
    }
    .el-button {
      a {
        text-decoration: none;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        line-height: 21px;
      }
    }
  }
  .inputSearch {
    width: 288px;
    display: flex;
    input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
    .el-input__inner {
      width: 288px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.8);
    }

    .el-input__prefix {
      .el-input__icon {
        line-height: 32px;
      }
    }
  }
  .Publish_tittle {
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    margin: 22px 0 22px 0;
    display: flex;
    .homePage_logo {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      a {
        width: 20px;
        height: 20px;
      }
    }
    .get_back {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #ffffff;
      margin: 0 8px 0 7px;
    }
  }
  .Publish_list {
    .el-table .descending .sort-caret.descending {
      border-top-color: rgba(239, 195, 148, 1);
    }
    .el-table .ascending .sort-caret.ascending {
      border-bottom-color: rgba(239, 195, 148, 1);
    }
    .el-table::before {
      height: 0px;
    }
    .el-table tr {
      background-color: #33353e !important;
    }
    .el-table tbody tr:hover > td {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }

    .el-table__body tr.hover-row.current-row > td,
    .el-table__body tr.hover-row.el-table__row--striped.current-row > td,
    .el-table__body tr.hover-row.el-table__row--striped > td,
    .el-table__body tr.hover-row > td {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
    // .el-table--enable-row-hover .el-table__body tr:hover > td {
    //   background-color: rgba(255, 255, 255, 0.1) !important;
    // }
    .el-table__header-wrapper {
      background: #33353e !important;
      .el-table__header {
        .has-gutter {
          tr {
            .el-table__cell {
              .cell {
                font-family: SourceHanSansCN-Regular, SourceHanSansCN !important;
                .caret-wrapper {
                }
              }
            }
          }
        }
      }
    }
    .el-table__body-wrapper {
      .el-table__body {
        .el-table__row {
          .el-table_11_column_44 {
            text-align: right !important;
          }
          .el-table__cell {
            .cell {
              display: flex;
              font-family: SourceHanSansCN-Regular, SourceHanSansCN !important;
              .is_passed {
                width: 8px;
                height: 8px;
                background: #52c41a;
                border-radius: 50%;
                margin: 8px 4px 0 0;
              }
            }
          }
        }
      }
    }
    .customWidth {
      width: 50%;
      .el-form-item__label {
        text-align: left !important;
      }
      .el-input {
        width: 200px !important;
      }
      .el-popper {
        .popper__arrow {
          border-bottom-color: #1ebef4 !important;
          left: 50% !important;
          visibility: hidden;
          .el-scrollbar {
            appearance: none;
            -moz-appearance: none; /* Firefox */
            -webkit-appearance: none; /* Safari 和 Chrome */
          }
        }
      }
      .el-dialog__headerbtn:focus .el-dialog__close,
      .el-dialog__headerbtn:hover .el-dialog__close {
        color: #33353e !important;
      }
      .el-dialog__body {
        .el-form {
          .textarea-item {
            margin: 24px 0 0 0 !important;
            .el-form-item__content {
              height: 330px !important;
              .textarea {
                .el-textarea__inner {
                  min-height: 330px !important;
                }
              }
            }
          }
        }
      }
      .el-dialog__footer {
        .el-button {
          padding: 8px 16px !important;
        }
        .el-button--primary {
          background-color: #33353e;
          border-color: #33353e;
        }
        .el-button--default:active {
          color: #33353e;
          border-color: #efc394;
          background: #fff;
        }
        .el-button--default:hover {
          background: #fff;
          color: #33353e;
          border-color: #efc394;
          outline: 0;
        }
      }
    }
  }
}
.el-select-dropdown__item.selected {
  color: #606266 !important;
}
</style>
<style lang="scss" >
.el-message-box__wrapper {
  position: fixed;
  top: 0;
  bottom: 160px !important;
  left: 0;
  right: 550px;
  //text-align: right;
  .Teletter-confirm {
    width: 384px;
    // height: 108px !important;
    background: rgba(51, 53, 62, 1);
    border: none;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.2),
      0px 6px 16px 0px rgba(22, 23, 28, 0.32),
      0px 3px 6px -4px rgba(51, 53, 62, 0.48);
    border-radius: 4px;
    padding-bottom: 0 !important;
    .el-message-box__header {
      padding: 16px 26px 25px 22px !important;
      height: 24px;
      line-height: 24px;
      .el-message-box__title {
        width: 320px !important;
        span {
          width: 64px;
          height: 24px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 0.85);
          line-height: 24px;
        }
      }
      .el-message-box__headerbtn {
        height: 24px !important;
        line-height: 24px !important;
        right: 26px !important;
        .el-message-box__close {
        }
      }
      .el-message-box__headerbtn:hover {
        color: #33353e !important;
      }
    }
    .el-message-box__content {
      padding: 0 15px;
      .el-message-box__container {
        .el-message-box__message {
          p {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: rgba(255, 255, 255, 0.65);
            line-height: 22px;
          }
        }
      }
    }
    .el-message-box__btns {
      height: 24px !important;
      padding: 0 26px 16px 15px !important;
      .el-button {
        width: 49px;
        height: 24px;
        border-radius: 2px;
        background: #33353e;
        border: 1px solid rgba(255, 255, 255, 0.2);
        span {
          font-size: 13px;
          font-family: MicrosoftYaHei;
          color: rgba(255, 255, 255, 0.65);
        }
      }
      .el-button--primary {
        background: #edbd88;
        span {
          color: #33353e;
        }
      }
      .el-button--small {
        padding: 0;
      }
    }
  }
}
.el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: transparent !important;
}
</style>
