import request from "@/utils/request"
//用户登陆
export function getUsersLogin(data) {
    //console.log("res",data)
    return request({
        method: 'post',
        url: '/entry/login/',
        data

    })

}

//获取用户信息
export function getUserInfo() {
    return request({
        method: 'get',
        url: '/entry/login/',
        headers: {
            token: localStorage.getItem("token")
        }
    })

}


//刷新token
export function getRefresh() {
    //console.log("res",data)
    return request({
        method: 'post',
        url: '/entry/refresh/',
        headers: {
            token: localStorage.getItem("token")
        }
    })

}

//登出
export function getLogout() {
    return request({
        method: 'post',
        url: 'entry/logout/',
        headers: {
            token: localStorage.getItem("token")
        }
    })

}
