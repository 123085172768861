import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)
const state = {
  topNavState: 'home',
  leftNavState: 'home',
  breadlist: [],
  contentInfo: {
    cuser: '',
    create_time: '',
    content:'',
  },
  listLength: 0,
  el_:'',// app.vue页面实例
}

const mutations = {
  changebreadlist(state, array) {
    state.breadlist = array
  },
  changecontentInfo(state, object) {
    state.contentInfo = object;
  },
  changeListLength(state, number) {
    state.listLength = number
  }
}



export default new Vuex.Store({
  state,
  getters: {
  },
  mutations,
  actions: {
  },
  modules: {
  },
  plugins: [persistedState()]
})
