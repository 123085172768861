<template>
  <!-- <div class="footer-container-box"> -->
  <div class="footer-container">
    <div class="footer-container-box">
      <div class="footer-box">
        <div class="row-01">
          <a target="_blank" href="https://www.rta-finance.com/#/">
            <img src="@/assets/img/rtaLogo/venture.svg" alt="" />
          </a>
          <div class="text">
            RTA-Finance
            成立于2020年3月，是一家致力于金融二级市场交易的综合服务型平台，主要涵盖交易知识普及推广、交易技术顾问、自营量化交易与资产管理等业务。
          </div>
          <div class="nav-logo">
            <div class="Community">
              <a target="_blank" href="https://discord.gg/rta"
                ><img src="@/assets/img/rtaLogo/discord.svg" alt=""
              /></a>
              <a target="_blank" href="https://www.youtube.com/@RTAFinance">
                <img src="@/assets/img/rtaLogo/youtube.svg" alt=""
              /></a>
              <a target="_blank" href="https://twitter.com/RtaFinance">
                <img src="@/assets/img/rtaLogo/twitter.svg" alt=""
              /></a>
              <a
                class="tradingview"
                target="_blank"
                href="https://cn.tradingview.com/u/RTA-Education/"
              >
                <img src="@/assets/img/rtaLogo/tradingview.svg" alt="" />
              </a>
              <a target="_blank" href="https://t.me/rtachannels"
                ><img src="@/assets/img/rtaLogo/telegram.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
        <div class="row-02">
          <h3>工具</h3>
          <div class="about">
            <div class="about_a">
              <a target="_blank" href="https://edu.rta-finance.com/">
                教学站
              </a>
              <a target="_blank" href="https://rta.trade"> 数据站 </a>

              <a target="_blank" href="https://lib.rta.academy/#/"> 百科站 </a
              ><a target="_blank" href="https://center.rta.academy/#/Home/">
                实盘站
              </a>
              <a target="_blank" href="https://shop.rta-finance.com/">
                商城站
              </a>
            </div>
          </div>
        </div>
        <div class="row-02 row-03">
          <h3>关于我们</h3>
          <div class="about">
            <div class="about_a">
              <a target="_blank" href="https://www.rta-finance.com/#/eduHome">
                公司介绍
              </a>
              <a target="_blank" href="https://www.rta-finance.com/#/ContactUs">
                合作联系
              </a>
              <a
                target="_blank"
                href="https://www.rta-finance.com/#/RecruitmentInformation"
              >
                职业机会
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-title">@2020-2024 RTA.Finance 版权所有</div>
    </div>
  </div>
</template>
  
<script>
//import {  } from ''//

export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>


<style  scoped lang="scss">
// .footer-container-box {
// background-color: rgba(51, 53, 62, 1);
// width: 100%;
// border-top: 1px solid rgba(255, 255, 255, 0.1);
.footer-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background-color: rgba(51, 53, 62, 1);
  width: 100%;
  // border-top: 1px solid rgba(255, 255, 255, 0.1);

  margin: 0 auto;
  .footer-container-box {
    width: 1200px;
    background-color: rgba(51, 53, 62, 1);
    .footer-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 100px;
      padding-bottom: 41px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      .row-01 {
        width: 66.72%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        img {
          width: 283px;
          height: 32px;
        }
        .text {
          margin-top: 36px;
          width: 415px;
          height: 66px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #d1d4dc;
          text-align: left;
          line-height: 22px;
        }
        .nav-logo {
          width: 415px;
          height: 20px;
          margin-top: 35px;

          .Community {
            width: 100%;
            height: 100%;
            text-align: right;
            display: flex;
            justify-content: flex-start;
            a {
              width: 20px;
              height: 20px;
              margin-right: 32px;
              overflow: hidden;
              text-decoration: none;

              img {
                width: 20px;
                height: 20px;
                position: relative;
                top: 0;
              }
            }
            a:hover img {
              top: -150px;
              filter: drop-shadow(0px 150px 0px #efc394);
            }
          }
        }
      }
      .row-02 {
        width: 15.94%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        h3 {
          width: 64px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          line-height: 24px;
          margin: 0 0 !important;
          text-align: left;
        }
        .about {
          width: 64px;
          display: flex;
          justify-content: flex-start;
          margin-top: 24px;
          .about_a {
            display: flex;
            flex-direction: column;

            a {
              margin: 0 auto;
              text-align: center;
              margin: 8px 0 0 0;
              text-decoration: none;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #d1d4dc;
              line-height: 21px;
              text-align: left;
            }
            a:hover {
              color: #efc394;
            }
          }
        }
      }
      .row-03 {
        width: 17.94% !important;
      }
    }
    .footer-title {
      width: 100%;
      height: 79px;
      line-height: 79px;
      font-size: 12px;
      font-family: ArialMT;
      color: #d1d4dc;
      float: left;
      text-align: left;
    }
  }
}
// }
</style>
