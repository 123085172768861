import request from "@/utils/request"

//我的发布，编辑未通过新增记录
export function getListEdit(data) {
    //console.log("res",data)
    return request({
        method: 'post',
        url: '/entry/edit/',
        data:data,
        headers: {
            token: localStorage.getItem("token")
        }

    })

}

//我的发布，删除新增记录
export function getListDelete(data) {
    //console.log("res",data)
    return request({
        method: 'post',
        url: '/entry/delete/',
        data:data,
        headers: {
            token: localStorage.getItem("token")
        }

    })

}

//获取详情
export function getUserInfo() {
    return request({
        method: 'post',
        url: '/entry/addinfo/',
        headers: {
            token: localStorage.getItem("token")
        }
    })

}

