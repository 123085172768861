<template>
  <div class="header_nav" style="">
    <div class="nav_breadcrumb">
      <el-breadcrumb separator="/" v-show="isShow">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
        <el-breadcrumb-item
          v-for="(item, index) in breadinfo"
          :key="item + index"
        >
          <template v-if="breadinfo.length === index + 1">
            <span style="color: #ffffff">{{ item }}</span>
          </template>
          <template v-else>
            {{ item }}
          </template>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <template>
        <div class="sign" v-show="id != 0 && isShow">
          <div class="author">作者：{{ cuser }}</div>
          <div class="createTtime">创建时间：{{ create_time }}</div>
        </div>
      </template>
      <div v-show="!isShow">
        <div class="search_result">
          <span class="spanOne">相关内容</span>
          <span class="spanTwo">为你找到约{{ listLength }}个结果</span>
        </div>
      </div>
    </div>
    <!-- <div class="nav_container">
      <button  @click="redactClick">编辑</button>
      
    </div> -->
  </div>
</template>
<script>
import { createLogger } from "vuex";
export default {
  components: {},
  data() {
    return {
      id: "",
      isShow: true,
    };
  },
  computed: {
    breadinfo() {
      return this.$store.state.breadlist;
    },
    cuser() {
      return this.$store.state.contentInfo.cuser;
    },
    create_time() {
      return this.$store.state.contentInfo.create_time;
    },
    listLength() {
      return this.$store.state.listLength;
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.path.indexOf("test") > -1) {
          this.isShow = false;
        } else {
          this.isShow = true;
        }
        this.id = this.$route.query.id;
      },
    },
  },
  created() {
    //刷新清空面包屑数据
    this.$store.state.breadlist = [];
  },

  mounted() {
    if (this.$route.query.id == undefined) {
      this.id = 0;
      this.breadinfo.push("简介");
    } else {
      this.id = this.$route.query.id;
    }
    //console.log("this.breadinfo.length",this.$store.state.breadlist);
    this.$store.state.breadlist = Array.from(new Set(this.breadinfo));
    // this.id = this.$route.query.id;
  },
  methods: {
    redactClick(){
      this.$router.replace({ path: "/Redact"});
    }
  },
};
</script> 


<style lang="scss">
.header_nav {
  display: flex;
  justify-content: space-between;
  .nav_breadcrumb {
    .el-breadcrumb {
      .el-breadcrumb__item {
        .el-breadcrumb__inner {
          color: rgba(255, 255, 255, 0.8) !important;
        }
        .el-breadcrumb__separator {
          color: rgba(255, 255, 255, 0.8) !important;
        }
      }
    }
    .sign {
      // padding-left: 20px;
      display: flex;
      font-size: 12px;
      font-family: SourceHanSansCN-Light, SourceHanSansCN;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.8);
      line-height: 18px;
      height: 23px;
      line-height: 23px;
      position: fixed;
      top: 0;
      width: 100%;
      top: 135px;
      z-index: 1000;
      .author {
      }
      .createTtime {
        margin-left: 24px;
      }
    }
    .search_result {
      text-align: left;
      .spanOne {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        line-height: 21px;
      }
      .spanTwo {
        font-size: 14px;
        font-family: SourceHanSansCN-Light, SourceHanSansCN;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.8);
        line-height: 21px;
        margin-left: 12px;
      }
    }
  }
  .nav_container {
    button{
      width: 64px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.8);
      background: #33353E;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>