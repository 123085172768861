<template>
  <div id="app" v-if="isReload">
    <router-view />
    <!-- <button onclick="handleScroll"></button> -->
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isReload: true,
    };
  },
  mounted() {},
  methods: {
    //刷新不会出现空白页面
    reload() {
      this.isReload = false;

      this.$nextTick(() => {
        this.isReload = true;
      });
    },
  },
};
</script>

<style lang="scss" >
#app{
  width: 100vw;
}
body {
  margin: 0 !important;
  padding:0 !important;
}
</style>