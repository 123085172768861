<template>
  <!--
    v-model = "content"  获取编辑器里输入的内容；
　　:config = "editorCnfig" 需要配置的项；
 　　@ready = "onReady"  编辑器初始化 -->
  <div id="ykEditor">
    <ckeditor
      ref="myEditor"
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
      @ready="onEditorReady"
    ></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import "@ckeditor/ckeditor5-build-classic/build/translations/zh-cn";
import axios from "axios";

export default {
  name: "ClassicEditor",

  components: {
    //局部注册方式
    ckeditor: CKEditor.component,
  },

  props: {
    content1: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },

  data() {
    return {
      // Use the <ckeditor> component in this view.
      editor: ClassicEditor,
      editorData: this.content1,
      editorConfig: {
        placeholder: this.placeholder,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "uploadImage",
            "blockQuote",
            "insertTable",
            "mediaEmbed",
            "undo",
            "redo",
          ],
        },
        image: {
          toolbar: [
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
            "|",
            "toggleImageCaption",
            "imageTextAlternative",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
        fontSize: {
          options: [
            8,
            9,
            10,
            11,
            12,
            "default",
            14,
            16,
            18,
            20,
            22,
            24,
            26,
            28,
            36,
            44,
            48,
            72,
          ],
        },
        highlight: {
          options: [
            {
              model: "blackPen",
              class: "pen-black",
              title: "黑色",
              color: "var(--ck-highlight-pen-black)",
              type: "pen",
            },
            {
              model: "redPenPen",
              class: "pen-red",
              title: "红色",
              color: "var(--ck-highlight-pen-red)",
              type: "pen",
            },
          ],
        },

        language: "zh-cn",
        // 后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
        //应该是{"uploaded":1,"url":"/"}
        //或者{"uploaded":true,"url":"/"}
        ckfinder: {
          uploadUrl: "https://apilib.rta.academy/ckeditornew/upload/",
        },
      },
    };
  },
  computed: {},
  methods: {
    onEditorReady(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return {
          upload: () => {
            // 执行文件上传逻辑
            return loader.file.then((file) => {
              // 执行文件上传请求，并在上传成功后处理后端返回的图片路径
              const param = new FormData();
              param.append("upload", file);
              axios
                .post("https://apilib.rta.academy/ckeditornew/upload/", param, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then((response) => {
                  const {
                    data: { url },
                  } = response;
                  const imageUrl = `https://apilib.rta.academy${url}`;
                  this.handleBackendResponse(imageUrl);
                  return {
                    default: imageUrl,
                  };
                })
                // .catch((err) => {});
            });
          },
        };
      };
    },
    handleBackendResponse(imageUrl) {
      // 更新编辑器的内容，插入图片
      this.editorData += `<img src="${imageUrl}">`;
    },
    getContent() {
      return this.editorData;
    },

    setContent(val) {
      this.editorData = val;
    },
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 400px;
}
</style>

