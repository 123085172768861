<template>
  <div class="box">
    <div class="content">
      <div v-if="id == 0" class="">
        <Dashboard />
      </div>

      <div id="question-id" v-if="content" style="" class="">
        <div v-viewer v-html="content" class="fwb-body"></div>
      </div>
    </div>

    <div v-if="id == 0" class="footer-sec">
      <div class="container" style="margin: 0">
        <Footer />
      </div>
    </div>

    <div v-if="content" class="footerHref">
      <div class="tag">
        <a href="https://rta.academy/" target="_blank"> 官方网站 </a>
      </div>
      <div class="tag">
        <a href="https://rta.trade/" target="_blank"> 数据站 </a>
      </div>
      <div class="tag"><a href="" target="_blank"> 使用帮助 </a></div>
      <div class="tag">
        <a href="https://www.youtube.com/c/RTAAcademy" target="_blank">
          YouTube
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Footer from "@/layout/Footer";
import MathJax from "@/utils/globalVariable";
import { getDirectoryEntryData } from "@/api/Home.js";
import Dashboard from "@/views/dashboard/index.vue";
// 导入组件
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  components: { Dashboard, ElImageViewer, Footer },
  data() {
    return {
      id: "",
      content: "",
      cuser: "",
      create_time: "",
    };
  },
  watch: {
    $route: {
      handler() {
        this.id = this.$route.query.id;
        this.getByIdArticleData();
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getByIdArticleData();
    } else {
      this.getByIdArticleData();
    }
  },
  activated() {
    // setTimeout(() => {
    //   this.$nextTick(() => {
    //     //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
    //     if (this.commonsVariable.isMathjaxConfig) {
    //       //判断是否初始配置，若无则配置。
    //       this.commonsVariable.initMathjaxConfig();
    //     }
    //     this.commonsVariable.MathQueue("question-id"); //传入组件id，让组件被MathJax渲染
    //   });
    // }, 1000);
  },
  mounted() {},
  methods: {
    rTime(date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    async getByIdArticleData() {
      if (this.$route.query.id > 0) {
        this.$emit("changeTree", this.$route.query.id);

        const { data: res } = await getDirectoryEntryData(this.$route.query.id);

        this.content = res.ret_data.content;
        const maiDom = document.querySelector(".app-body");
        //返回顶部
        if (maiDom) {
          maiDom.scrollTop = 0;
        }
        // this.cuser = res.ret_data.cuser;
        // this.create_time = this.rTime(res.ret_data.create_time);
        this.$store.commit("changecontentInfo", {
          cuser: res.ret_data.cuser,
          create_time: this.rTime(res.ret_data.create_time),
          content: res.ret_data.content,
        });
        let that = this;
        setTimeout(() => {
          that.$nextTick(() => {
            //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
            if (this.commonsVariable.isMathjaxConfig) {
              //判断是否初始配置，若无则配置。
              this.commonsVariable.initMathjaxConfig();
            }
            this.commonsVariable.MathQueue("question-id"); //传入组件id，让组件被MathJax渲染
          });
        }, 200);
      } else {
        this.content = " ";
        this.$router.push({ path: "/article", query: { id: 0 } });
        this.$store.state.breadlist = ["首页"];
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/contents.scss";
</style>
