<template>
  <div class="pagination">
    <!-- //分页器封装文件 -->
    <el-pagination
      @size-change="sizeChange"
      @current-change="currentChange"
      background
      layout="sizes,prev, pager, next"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'Paging',
  data() {
    return {
      pageNum: 1, //当前页数为第一页
      pageSizes: [5, 10, 20, 50, 100], //每页显示多少条
    };
  },
  //子组件接收父组件的值
  props: {
    total: {
      //总数据条数
      required: false, //是否必填
      default: 0, //默认值为0
    },
    pageSize: {
      required: false, //是否必填
      default: 10, //默认显示10条
    },
  },
  watch: {
    //监听页数变化
    pageNum(val, index) {
      if (typeof val === 'number') {
        this.pageNum = val;
      }
    },
  },
  // 事件方法
  methods: {
    // 每页展示几条变化
    sizeChange(val) {
      //子组件向父组件传值
      this.$emit('sizeChange', val);
    },
    // 页码变化
    currentChange(val) {
      //子组件向父组件传值
      this.$emit('currentChange', val);
    },
  },
};
</script>
<style lang="scss" scope>
.pagination {
  margin-top: 40px !important;
  text-align: center;
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    background-color: rgba(80, 82, 89, 1) !important;
    color: #d1d4dc;
    min-width: 32px;
    min-height: 32px;
    border-radius: 2px;
  }
  .el-pagination {
    .el-pagination__sizes {
      .el-select {
        .el-input {
          .el-input__inner {
            height: 32px;
            border: none !important;
            background-color: rgba(80, 82, 89, 1) !important;
            color: #d1d4dc;
          }
        }
      }
    }
    .el-pager {
      li {
        padding: 4px 4px;
      }
    }
    .el-pager li:not(.disabled):hover {
      color: rgba(51, 53, 62, 1) !important;
      background: #edbd88 !important;
    }
    .el-pager li:not(.disabled).active {
      color: rgba(51, 53, 62, 1) !important;
      background: #edbd88 !important;
    }
  }
}
</style>