<template>
  <div class="header">
    <div class="header-cont">
      <div class="backgroundPicture">
        <a class="cont" href="https://lib.rta.academy/#/article?id=0">
          <img src="../assets/img/rtaLogo/rta.svg" alt="" />
        </a>
        <div class="libraryIcon">
          <img src="../assets/img/rtaLogo/relienceTradingLibrary.svg" alt="" />
        </div>
        <!-- 显示用户头像 -->
        <div style="display: none" ref="popoverRef">
          <!-- trigger="hover" -->
          <el-popover
            placement="bottom"
            width="200"
            class="headPortrait"
            popper-class="wrap"
          >
            <div class="loginPopover" style="text-align: center">
              <div class="user-name">
                <span onload="load_data()">
                  <div class="vip_avatar">
                    <div class="vipPic"><img :src="imgUrl" alt="" /></div>
                    <div class="avatar">
                      <img :src="avatar" alt="" style="" />
                    </div>
                  </div>
                  <div class="nickName" :title="nickName">{{ nickName }}</div>
                </span>
              </div>
              <div class="user-button" v-if="isCreate">
                <!-- <el-button
                  type="text"
                  class="newly_increased"
                  @click="addedClick"
                  >新增词条</el-button
                > -->
                <el-button
                  type="text"
                  class="newly_catalogue;"
                  @click="$router.push('/catalogue')"
                  >添加记录</el-button
                >
              </div>
              <div class="log_out">
                <el-button type="text" @click="logoutClick">退出登录</el-button>
              </div>
            </div>
            <el-button slot="reference">
              <img :src="avatar" alt="" style="" />
            </el-button>
            <div slot="reference" class="nickName" :title="nickName">
              {{ nickName }}
            </div>
            <div slot="reference" class="vipPic">
              <img :src="imgUrl" alt="" />
            </div>
          </el-popover>
        </div>
        <!-- <el-dialog
          title="增加词条"
          customClass="customWidth"
          :visible.sync="newlyIncreasedClick"
          :close-on-click-modal="false"
          :append-to-body="false"
          :before-close="handleClose"
          @close="closeDialog"
        >
          <el-form
            :model="newlyIncreasedForm"
            :rules="rules2"
            ref="newlyIncreased"
          >
            <el-form-item label="类别" :label-width="formWidth" prop="category">
              <el-select
                v-model="newlyIncreasedForm.category"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in categoryoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="词条名称" :label-width="formWidth" prop="name">
              <el-input
                v-model="newlyIncreasedForm.name"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="VIP等级开放等级"
              :label-width="formWidth"
              prop="vip"
            >
              <el-select v-model="newlyIncreasedForm.vip" placeholder="请选择">
                <el-option
                  v-for="item in vipOptions"
                  :key="item.value2"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" :label-width="formWidth" prop="content">
              <ckeditor
                @sendContnet="getContent"
                id="editor"
                :content="newlyIncreasedForm.content"
              ></ckeditor>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="open">取 消</el-button>
            <el-button type="primary" @click="getNewlyIncreased"
              >新增</el-button
            >
          </div>
        </el-dialog> -->
        <div class="login" ref="loginRef" style="display: flex">
          <el-button @click="dialogFormVisible = true" class="loginPage"
            >登录
          </el-button>
          <el-dialog
            width="1139px"
            :visible.sync="dialogFormVisible"
            @close="resetRegister"
            class="previewDialog"
            :close-on-click-modal="false"
          >
            <!-- <Login ref="LoginRef" /> -->
            <div class="popup-content">
              <div class="loginRight">
                <div style="text-align: left">
                  <img src="../assets/img/rtaLogo/rta.svg" alt="" />
                  <div class="title">二级交易市场综合服务平台</div>
                  <el-form
                    :model="fromData"
                    :rules="rules"
                    ref="fromData"
                    class="demo-ruleForm"
                  >
                    <el-form-item prop="nickname" class="inputDeep">
                      <span>账号</span>
                      <el-input
                        v-model="fromData.name"
                        placeholder="请输入邮箱"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                      <span>密码</span>
                      <el-col :span="23">
                        <el-form-item class="inputDeep">
                          <el-input
                            type="password"
                            placeholder="请输入密码"
                            class="psd"
                            v-model="fromData.password"
                            oninput="value=value.replace(/^[a-zA-Z0-9]{30,32}$/)"
                            v-if="ifDisplay"
                          />
                          <el-input
                            type="text"
                            placeholder="请输入密码"
                            class="psd"
                            v-model="fromData.password"
                            oninput="value=value.replace(/^[a-zA-Z0-9]{30,32}$/)"
                            v-else
                          />
                        </el-form-item>
                      </el-col>
                      <el-col :span="1">
                        <el-form-item class="pasEye">
                          <span
                            v-show="ifDisplay"
                            @click="ifDisplay = !ifDisplay"
                            ><span class="iconfont icon-biyanjing"></span
                          ></span>
                          <span
                            v-show="!ifDisplay"
                            @click="ifDisplay = !ifDisplay"
                            ><span class="iconfont icon-yanjing"></span
                          ></span>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitClick"
                      >登录</el-button
                    >
                  </div>
                  <div class="sign_in">
                    <el-checkbox
                      style="height: 19.22px; line-height: 19.22px"
                      v-model="isRemember"
                      >记住密码</el-checkbox
                    >
                    <a
                      target="_blank"
                      href="https://edu.rta-finance.com/register?goto=https%3A//edu.rta.academy/page/commissionagreement"
                      class="sign-in"
                      >立即注册</a
                    >
                  </div>
                </div>
              </div>
              <div class="loginLeft">
                <img src="../assets/img/login/welcome.svg" alt="" />
              </div>
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Login from "./login/login.vue";
import { getUsersLogin, getUserInfo, getLogout } from "@/api/login.js";
import { geiAddCategory, getVipData, getCateAddData } from "@/api/Home.js";
import ckeditor from "@/components/ckeditor.vue";
export default {
  components: { ckeditor },
  data() {
    return {
      dialogFormVisible: false,
      landingForm: {
        value: "",
        phone: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      isCreate: false,
      formLabelWidth: "0px",
      activeName: "first",

      phoneOptions: [],
      fromData: {
        name: "",
        password: "",
      },
      isRemember: false,
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      rules2: {
        name: [
          { required: true, message: "请输入词条名称", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 15 个字符",
            trigger: "blur",
          },
        ],
        category: [
          { required: true, message: "请选择词条类别", trigger: "blur" },
        ],
        vip: [
          { required: true, message: "请选择VIP等级开放等级", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入词条内容", trigger: "blur" },
        ],
      },
      ifDisplay: true,
      nickName: "",
      avatar: "",
      vipName: "",
      imgUrl: "",
      //新增词条
      newlyIncreasedClick: false,
      newlyIncreasedForm: {
        name: "",
        category: "",
        vip: "",
        content: "",
      },
      categoryoptions: [],
      value: "",
      vipOptions: [],
      formWidth: "130px",
      infoText: "",
      content: "",
    };
  },
  created() {
    //发送登陆事件
    this.$EventBus.$on("changedialogFormVisible", () => {
      this.dialogFormVisible = true;
    });
  },
  mounted() {
    this.getCookie();
    this.load_data();
  },
  inject: ["reload"],
  methods: {
    //页面刚加载的时候，开始判断，如果有用户名，就显示在页面上，如果没有用户名的话，就显示登录名称
    async load_data() {
      var theme = localStorage.getItem("token");
      if (theme == null || theme == "") {
        // 未登录不显示用户信息
        this.$refs.popoverRef.style.display = "none";
      } else {
        // 显示用户信息
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.nickName = userInfo.nickname;
        this.vipName = userInfo.vipName;
        this.avatar = userInfo.avatar;
        this.isCreate = userInfo.isCreate;
        this.$refs.popoverRef.style.display = "flex";
        this.$refs.loginRef.style.display = "none";
        var text = this.vipName;
        //根据名字获取相应的图片

        if (text == null) {
          this.imgUrl = "";
        } else {
          switch (text) {
            case "A1":
              this.imgUrl = require("@/assets/img/rtaLogo/A1.svg");
              break;
            case "A2":
              this.imgUrl = require("@/assets/img/rtaLogo/A2.svg");
              break;
            case "A3":
              this.imgUrl = require("@/assets/img/rtaLogo/A3.svg");
              break;
            case "A4":
              this.imgUrl = require("@/assets/img/rtaLogo/A4.svg");
              break;
            case "A5":
              this.imgUrl = require("@/assets/img/rtaLogo/A5.svg");
              break;
            case "A6":
              this.imgUrl = require("@/assets/img/rtaLogo/A6.svg");
              break;
            case "A7":
              this.imgUrl = require("@/assets/img/rtaLogo/A7.svg");
              break;
            case "A8":
              this.imgUrl = require("@/assets/img/rtaLogo/A8.svg");
              break;
            case "A9":
              this.imgUrl = require("@/assets/img/rtaLogo/A9.svg");
              break;
          }
        }
        // this.getPic();
      }
    },
    //获取富文本编译器内容
    getContent(val) {
      this.newlyIncreasedForm.content = val;
    },
    //新增词条点击事件
    addedClick() {
      this.newlyIncreasedClick = true;
      //请求vip信息
      this.vipOptions = [];
      this.categoryoptions = [];
      this.getVipLevel();
      this.getCategoryName();
    },
    //关闭前的回调，会暂停 Dialog 的关闭这个是对话框右上角叉
    handleClose(done) {
      this.$confirm("信息未保存，确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //对话框取消提示
    open(done) {
      this.$confirm("信息未保存，确认关闭？")
        .then((_) => {
          this.newlyIncreasedClick = false;
        })
        .catch((_) => {
          this.newlyIncreasedClick = true;
        });
    },
    // 获取vip信息
    async getVipLevel() {
      const { data: res } = await getVipData();
      res.ret_data.vips.forEach((item) => {
        const vipObj = {
          value: item.id,
          label: item.vip_name,
        };
        this.vipOptions.push(vipObj);
      });
    },
    // 获取类别
    async getCategoryName() {
      const { data: res } = await getCateAddData();
      //console.log(res.ret_data);
      res.ret_data.categories.forEach((item) => {
        const categoryObj = {
          value: item.id,
          label: item.name,
        };
        this.categoryoptions.push(categoryObj);
      });
    },
    //请求新增词条接口
    getNewlyIncreased() {
      this.$refs.newlyIncreased.validate(async (valid) => {
        if (valid) {
          let data = new FormData();
          data.append("name", this.newlyIncreasedForm.name);
          data.append("vip", this.newlyIncreasedForm.vip);
          data.append("category", this.newlyIncreasedForm.category);
          data.append("content", this.newlyIncreasedForm.content);
          const { data: res } = await geiAddCategory(data);
          if (res.ret_code == 0) {
            this.$message({
              message: "增加词条成功！",
            });
            this.newlyIncreasedClick = false;
            this.newlyIncreasedForm = {};
          }
        } else {
          this.$message({
            type: "error",
            message: "增加词条失败！",
          });
          return false;
        }
      });
    },
    //关闭弹框的事件
    closeDialog() {
      this.newlyIncreasedForm = {}; //清空数据
    },
    //登录事件
    submitClick() {
      this.$refs["fromData"].validate(async (valid) => {
        if (valid) {
          const { data: res } = await getUsersLogin(this.fromData);
          if (res.ret_code == 0) {
            this.$message({
              message: "登录成功!",
              type: "登录成功",
            });
            //登陆成功后关闭模态框
            this.dialogFormVisible = false;
            this.resetRegister();
            // 存储数据到localStoreage
            localStorage.setItem("token", res.ret_data);
            // 请求用户数据
            const { data: userres } = await getUserInfo();
            let userInfo = {
              nickname: userres.ret_data.nickName,
              avatar: userres.ret_data.avatar,
              vipName: userres.ret_data.vipName,
              isCreate: userres.ret_data.isCreate,
              loginTime: Date.parse(new Date()),
              createLimit: userres.ret_data.createLimit,
            };
            this.isCreate = true;
            (this.nickName = userres.ret_data.nickName),
              localStorage.setItem("userInfo", JSON.stringify(userInfo));
            this.load_data();
            //通过this.$nextTick()获取dom更新之后的值.
            this.$nextTick(() => {
              this.$router.push("/");
            });

            // 判断复选框是否被勾选; 勾选则调用配置Cookie方法
            if (this.isRemember) {
              // 记住密码
              this.setCookie(this.fromData.name, this.fromData.password, 30); // 保存期限为30天
            } else {
              this.clearCookie(); // 清空 Cookie
            }
            this.reload();
          } else {
            this.$message.error(res.ret_msg);
          }
        }
      });
    },

    //关闭对话框事件
    resetRegister() {
      // 重置登录信息
      this.fromData.name = "";
      this.fromData.password = "";
    },
    // 退出登录
    async logoutClick() {
      const { data: res } = await getLogout();
      //console.log('退出登录', res);
      if (res.code === 0) {
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        this.reload();
        this.$refs.popoverRef.style.display = "none";
        this.$refs.loginRef.style.display = "flex";
        this.$router.replace({ path: "/article" });
      } else {
        this.$message.error(res.msg);
      }
    },
    // //退出登录
    // logoutClick() {
    //   this.reload();
    //   localStorage.removeItem("token");
    //   this.$refs.popoverRef.style.display = "none";
    //   this.$refs.loginRef.style.display = "flex";
    //   this.$router.replace({ path: "/article" });
    // },
    // 设置Cookie
    setCookie(username, password, exdays) {
      // 用户名, 密码, 保存天数
      let exdate = new Date(); // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);
      // 字符串拼接cookie
      window.document.cookie =
        "userName=" + username + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd=" + password + ";path=/;expires=" + exdate.toGMTString();
    },

    // 读取Cookie
    getCookie() {
      if (document.cookie.length > 0) {
        let arr = document.cookie.split("; ");
        for (let i = 0; i < arr.length; i++) {
          let arr2 = arr[i].split("="); // 再次切割
          // 判断查找相对应的值
          if (arr2[0] === "userName") {
            this.fromData.name = arr2[1]; // 保存到保存数据的地方
          } else if (arr2[0] === "userPwd") {
            this.fromData.password = arr2[1];
          }
        }
        this.isRemember = true;
      }
    },

    // 清除Cookie
    clearCookie() {
      this.setCookie("", "", -1); // 修改2值都为空，天数为负1天
    },
  },
};
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 100px;
  .header-cont {
    width: 100%;
    height: 100px;

    .backgroundPicture {
      background-image: url(../assets/img/rtaLogo/headerImage.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      height: 100px !important;
      .cont {
        img {
          margin: 20px 20px;
        }
      }
      .libraryIcon {
        img {
          margin-top: 37px;
        }
      }
      .headPortrait {
        margin-right: 20px;
        .el-popover__reference-wrapper {
          display: flex;
          flex-direction: row;
          .el-popover__reference {
            padding: 0 0;
            margin-top: 20px;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }
          }
          .nickName {
            margin: 25px 0 0 8px;
            font-size: 16px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #ffffff;
            line-height: 19px;
            width: 50px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .vipPic {
            margin: 25px 0 0 8px;
          }
        }
      }

      .login {
        .el-dialog {
          display: flex;
          flex-direction: column;
          margin: 0 !important;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          /*height:600px;*/
          // max-height: calc(100% - 200px);
          // max-width: calc(100% - 30px);
        }
        .el-dialog .el-dialog__body {
          height: 100%;
          // flex: 1;
          // overflow: auto;
        }

        .loginPage {
          margin: 20px 20px;
          width: 48px;
          height: 24px;
          background: #efc394;
          border: 1px solid #efc394;
          border-radius: 4px;
          position: relative;
          span {
            line-height: 21px;
            font-weight: 400;
            font-size: 14px;
            position: absolute;
            left: 10px;
            top: 2px;
            color: #ffffff;
          }
        }

        .el-button--text {
          color: #ffffff !important;
        }
        .el-button--text:active {
          color: #3a8ee6;
          background-color: transparent;
        }
        .el-dialog__header .el-dialog__close:hover {
          color: #efc394; /* 这里设置悬停时的颜色 */
        }
        .previewDialog {
          .el-dialog {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
            .el-dialog__header {
              padding: 0 !important;
              .el-dialog__headerbtn {
                top: 35px !important;
                right: 25px !important;
                .el-icon-close {
                  font-size: 24px !important;
                }
              }
            }
            .el-dialog__body {
              padding: 0 !important;
              width: 100%;
              height: 100%;
              padding: 0 !important;
              width: 100%;
              height: 100%;
              box-shadow: 0px 2px 40px -40px rgba(0, 0, 0, 0.6);
              background: url("../assets/img/login/login.svg") center center
                no-repeat;
              .popup-content {
                display: flex;
                //justify-content: space-between;
                width: 100%;

                .loginLeft {
                  width: 60% !important;
                  height: 100%;
                  text-align: center;
                  margin-top: 265px;
                  img {
                    width: 456px;
                    height: 233px;
                  }
                }
                .loginRight {
                  width: 40%;
                  height: 100%;
                  padding: 75px 0 80px 111px;
                  .loginTittle {
                    height: 21px;
                    text-align: center;
                    margin-bottom: 22px;
                    line-height: 21px;
                    span {
                      height: 21px;
                      font-size: 16px;
                      font-weight: 600;
                      color: #616161;
                    }
                  }
                  img {
                    // width: 364px;
                    height: 40px;
                  }
                  .title {
                    text-align: left;
                    font-size: 20px;
                    font-family: MicrosoftYaHei;
                    color: #ffffff;
                    line-height: 32px;
                    letter-spacing: 11px;
                    margin-top: 15px;
                  }
                  .el-form {
                    text-align: left;
                    margin-top: 60px;
                    .el-form-item {
                      .el-form-item__content {
                        width: 364px;
                        span {
                          font-size: 14px;
                          font-family: PingFangSC-Regular, PingFang SC;
                          font-weight: 400;
                          color: #ffffff;
                          line-height: 20px;
                        }
                        .el-input {
                          .el-input__inner {
                            background: rgba(255, 255, 255, 0.1) !important;
                            border-radius: 4px !important;
                            border: 1px solid #979797 !important;
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #eaeaea;
                            line-height: 18px;
                          }
                          .el-input__inner:focus {
                          }
                        }
                        .el-form-item__error {
                          font-size: 12px;
                          font-family: MicrosoftYaHei;
                          color: #ff3040;
                          line-height: 16px;
                        }
                      }
                    }

                    .el-input__inner {
                      -webkit-appearance: none !important;
                      background-color: #fff !important;
                      background-image: none !important;
                      border-radius: 0 !important;
                      border: 0px !important;
                      border-bottom: 1px solid #cccccc !important;
                      width: 100% !important;
                      height: 48px;
                    }
                    .el-select .el-input__inner {
                      border-left: 0px !important;
                      padding-left: 0 !important;
                    }
                    .pasEye {
                      .el-form-item__content {
                        width: 30%;
                        span {
                          line-height: 48px !important;
                        }
                      }
                      span {
                        color: #bfbfbf;
                        float: right;
                        line-height: 40px;
                        .svg-icon {
                          font-size: 20px;
                        }
                      }
                    }
                  }
                  .dialog-footer {
                    margin-top: 60px;
                    .el-button--primary {
                      border: none;
                      width: 364px;
                      height: 48px;
                      background: #efc394;
                      border-radius: 4px;
                      margin: 0 !important;
                      span {
                        font-size: 16px;
                        font-family: ArialMT;
                        color: #000000;
                        line-height: 24px;
                        font-weight: 600;
                      }
                    }
                  }
                  .sign_in {
                    margin-top: 8px;
                    display: flex;
                    justify-content: space-between;
                    width: 364px;
                    .checked {
                      margin-bottom: 16px;
                      .el-checkbox__input.is-checked + .el-checkbox__label {
                        color: rgba(153, 153, 153, 1);
                      }
                    }
                    .el-checkbox {
                      color: rgba(153, 153, 153, 1);
                    }
                    .el-checkbox__inner:hover {
                      border-color: #b29170 !important;
                    }
                    .el-checkbox__inner {
                      border-color: #606266;
                      color: rgba(153, 153, 153, 1);
                    }
                    .el-checkbox__input.is-checked .el-checkbox__inner,
                    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                      background-color: rgba(239, 195, 148, 1) !important;
                      border-color: rgba(239, 195, 148, 1) !important;
                    }
                    .el-checkbox__input.is-checked + .el-checkbox__label {
                      color: rgba(153, 153, 153, 1);
                    }
                    a {
                      background: transparent !important;
                      font-size: 14px;
                      font-family: MicrosoftYaHei;
                      color: rgba(239, 195, 148, 1);
                      line-height: 18px;
                      border: none;
                      text-decoration: none;
                    }
                  }

                  .secondPane .input-with .el-input__inner {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
        .el-dialog {
          display: flex;
          flex-direction: column;
        }
        // .el-dialog .el-dialog__body {
        //   flex: 1;
        //   overflow: auto;
        // }
      }
    }
    .customWidth {
      width: 50%;
      .el-form-item__label {
        text-align: left !important;
      }
      .el-input {
        width: 200px !important;
      }
      .el-dialog__footer {
        .el-button {
          padding: 8px 16px !important;
        }
        .el-button--primary {
          background-color: #b29170;
          border-color: #b29170;
        }
      }
    }
  }
}
.main {
  width: 100%;
  .manualMenu {
    //width:25% ;
    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 200px;
      min-height: 400px;
    }
  }
}

.el-popover[x-placement^="bottom"] .popper__arrow {
  /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
  border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}
.el-popover[x-placement^="bottom"] .popper__arrow::after {
  /*el-popover上边三角表层的颜色---也就是视觉上的角*/
  border-bottom-color: #31343e !important;
}

.el-popover {
  background-color: #31343e !important;
  //border-color: #31343e !important;
  padding: 0 !important;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.2),
    0px 6px 16px 0px rgba(0, 0, 0, 0.32), 0px 3px 6px -4px rgba(0, 0, 0, 0.48) !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  .loginPopover {
    // display: flex;
    // flex-direction: column;

    border-radius: 4px;
    width: 100%;
    .user-name {
      width: 100%;
      margin: 0 auto;
      font-size: 16px !important;
      color: #ffffff;
      padding: 16px 0 15px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      .vip_avatar {
        position: relative;
        .vipPic {
          width: 100%;
          margin-left: 5px;
          position: absolute;
          top: 27px;
          left: -5px;
        }
        .avatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          text-align: center;
          margin: 0 auto;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
        }
      }

      .nickName {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
    .user-button {
      //   display: flex;
      // flex-direction: column;
      width: 100%;
      margin: 4px 0 4px 0;
      .el-button {
        width: 100%;
        margin: 0;
        height: 40px;

        padding: 0 !important;
        span {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff !important;
          line-height: 21px;
        }
      }
    }
    .log_out {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      .el-button {
        width: 100%;
        margin: 0;
        height: 40px;
        margin: 4px 0 4px 0;
        padding: 0 !important;
        span {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff !important;
          line-height: 21px;
        }
      }
    }
    .el-button {
      //padding: 0 0 0 0 !important;
      // border-top: 1px solid rgba(255, 255, 255, 0.1);
      span {
        color: #efc394;
      }
    }
  }
}
.el-select-dropdown__item.selected {
  color: #606266 !important;
}
</style>
