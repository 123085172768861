// 在http.js中引入axios
import axios from 'axios'; // 引入axios
import store from '@/store/index'; // 引入vuex-store
import { getRefresh } from '@/api/login'
import domMessage from './messageOnce'

const messageOnce = new domMessage();

const service = axios.create({
    //baseURL: "http://192.168.2.59:8000/api/v1",//测试数据
    baseURL: "https://apilib.rta-finance.com/api/v1",//正式环境
    timeout: 10000, // 请求超时时间
    headers: {
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/json;charset=utf-8'
        }
    }
})


// request拦截器
service.interceptors.request.use(
    config => {
        //这里是每个请求的设置，比如每个请求都携带一个token，或者当为post请求时，引入qs格式化参数。
        // const token = localStorage.getItem('token');
        // token && (config.headers.token = token)

        // const token = localStorage.getItem('token');
        // if(token !== null){
        //     config.headers['token'] = token
        // }
        // 获取用户上次登录时间
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let token = localStorage.getItem('token');
        // console.log("userInfo", userInfo);
        if (userInfo != null) {
            let loginTime = userInfo.loginTime;
            // 获取当前时间
            let currentTime = Date.parse(new Date());
            // 判断当前时间距离上次登录时间差
            let statusTime = (currentTime - loginTime) / 1000 / 60;
            //let statusTime = 121.25;
            // console.log("statusTime:",statusTime,"currentTime:",currentTime)
            if (statusTime >= 120) {
                localStorage.removeItem("token");
                let currentToken = localStorage.getItem('token');
                //console.log("currentToken", currentToken);
                axios({
                    url: '/entry/refresh/',
                    method: 'post',
                    baseURL: "https://apilib.rta.academy/api/v1",
                    headers: {
                        'token': currentToken
                    }
                }).then(res => {
                    userInfo.loginTime = Date.parse(new Date());
                    localStorage.setItem('token', res.data.data);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));

                }).catch(() => {
                    console.log("err")
                    // localStorage.removeItem('userInfo');
                    // localStorage.removeItem('token');
                    localStorage.clear();
                    // alert('登录已过期,请重新登录')
                    messageOnce.messageBox({
                        duration: 10000,
                        title: '温馨提示',
                        message: '登录状态已过期，请重新登录',
                        customClass: 'sure-confirm',
                        confirmButtonClass: "sureBtn",
                        callback: () => {
                            location.reload()
                            this.$router.replace({ path: "/article" });
                        }
                    })

                    this.$EventBus.$emit('changedialogFormVisible')
                })
            }
        }
        return config
    },
    error => {
        console.log('请求失败:' + error)
        Promise.reject(error)
    }
)

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    msg = "错误的请求"
                    break;
                case 401:
                    msg = "未授权，请重新登录"
                    break;
                case 403:
                    msg = "拒绝访问"
                    break;
                case 404:
                    msg = "未找到该接口地址"
                    break;
                case 405:
                    msg = "请求未被允许"
                    break;
                case 408:
                    msg = "请求超时"
                    break;
                case 500:
                    msg = "服务端出错了"
                    break;
                case 501:
                    msg = "网络未实现"
                    break;
                case 502:
                    msg = "网络错误"
                    break;
                case 503:
                    msg = "服务不可用"
                    break;
                case 504:
                    msg = "网络超时"
                    break;
                case 505:
                    msg = "http版本不支持"
                    break;
                default:
                    msg = "连接错误"
                    break;
            }
        }
    }
);

export default service
