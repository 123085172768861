<template>
  <div class="ckedito_content">
    <ckeditor
      @sendContnet="getContent"
      id="editor1"
      :content="infoText"
    ></ckeditor>
  </div>
</template>
<script>
import ckeditor from "@/components/ckeditor.vue";
export default {
  components: { ckeditor },
  data() {
    return {
      
    };
  },
  computed: {
    infoText() {
      return this.$store.state.contentInfo.content;
    },
  },
  created() {
      
  },
  methods: {
    //获取富文本编译器内容
    getContent(val) {
      this.infoText = this.$store.state.contentInfo.content;
      
      
    },
  },
};
</script>
<style lang="scss">
.ckedito_content {
  display: flex;
  justify-content: center;
}
</style>