<template>
  <div class="box" style="overflow-y: auto; height: 100vh">
    <div class="Publish_header">
      <Top />
    </div>
    <div class="Publish_content">
      <div class="Publish_tittle">
        <div class="homePage_logo">
          <a href="https://lib.rta.academy/#/article?id=0"
            ><img src="@/assets/img/icon/homePage.png" alt=""
          /></a>
        </div>
        <div class="get_back">></div>
        <span>词条添加说明</span>
      </div>

      <div class="Publish_list"></div>
    </div>
  </div>
</template>
<script>
import Top from "@/layout/top.vue";
export default {
  components: { Top },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.Publish_header {
  border-bottom: 1px solid;
  border-image: linear-gradient(270deg, #33353e 0%, #f6daa8 100%) 1;
}
.Publish_content {
  margin: 0 40px 22px 40px;
  .publishNav {
    display: flex;
    justify-content: space-between;
    .inputSearch {
      margin: 16px 0 16px 0;
    }
    .el-button {
      a {
        text-decoration: none;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        line-height: 21px;
      }
    }
  }
  .Publish_tittle {
    font-size: 20px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    margin: 22px 0 22px 0;
    display: flex;
    .homePage_logo {
      width: 20px;
      height: 20px;
      margin-top: 2px;
      a {
        width: 20px;
        height: 20px;
      }
    }
    .get_back {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #ffffff;
      margin: 0 8px 0 7px;
    }
  }
  .Publish_list {
  }
}
</style>