import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Container from '@/container/Container'
import Login from '@/views/TheLogin.vue'
import Dashboard from '@/views/dashboard'
import Article from '@/views/article'
import Test from '@/views/search'
import Redact from '@/views/redact'
import Catalogue from '@/views/catalogue'
import Issue from '@/views/issue'
import Explain from '@/views/explain'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/catalogue',
    name: '新增词条记录',
    component: Catalogue
  },
  {
    path: '/explain',
    name: '词条添加说明',
    component: Explain
  },
  {
    path: '/',
    redirect: '/article',
    name: 'Container',
    component: Container,
    children: [
      { path: 'article', name: '首页', component: Article, },
      { path: 'test', name: '首页', component: Test, },
      { path: 'redact', name: '编辑', component: Redact, },
    ]
  },

]

const router = new VueRouter({
  routes: routes,
})

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push

//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router


