import request from "@/utils/request"
// //获取词条目录
export function getEntryData() {
    return request({
        method: 'get',
        url: '/entry/categories/',
        params: {

        }

    })

}
// 根据目录id获取词条
export function getDirectoryEntryData(categoryId) {
    return request({
        method: 'get',
        url: '/entry/entry/',
        params: {
            categoryId
        }

    })

}
// 根据标题查询词条
export function getTitleEntryData(content) {
    return request({
        method: 'get',
        url: '/entry/query/',
        params: {
            content
        }

    })

}
// 获取vip信息
export function getVipData() {
    return request({
        method: 'get',
        url: '/entry/vips/',
        headers:{
            token:localStorage.getItem('token')
        }
    })
}
// 获取类别
export function getCateAddData() {
    return request({
        method: 'get',
        url: '/entry/cateadd/',
        headers:{
            token:localStorage.getItem('token')
        }
    })
}
// 新增词条
export function geiAddCategory(data) {
    return request({
        method: 'post',
        url: '/entry/add/',
        data,
        headers:{
            token:localStorage.getItem('token')
        }

    })

}
//个人新增记录
export function getAddListData(data) {
    return request({
        method: 'post',
        url: 'entry/list/',
        data:data,
        headers:{
            token:localStorage.getItem('token')
        }
    })
}



